// extracted by mini-css-extract-plugin
export var Banner = "index-module--Banner--i4xT5";
export var BookButtonGroup = "index-module--BookButtonGroup--ZkIsU";
export var BookContent = "index-module--BookContent--oWojv";
export var BookDesc = "index-module--BookDesc--RxJ6R";
export var BookItem = "index-module--BookItem--UY2Pp";
export var BookList = "index-module--BookList--mpPui";
export var BookMall = "index-module--BookMall--Vii8X";
export var BookThumb = "index-module--BookThumb--ZnQyr";
export var DialogBook = "index-module--DialogBook--KcRKB";
export var DialogBookInfo = "index-module--DialogBookInfo--3Bgh8";
export var DialogBookWrap = "index-module--DialogBookWrap--UfX9x";
export var Price = "index-module--Price--0gkxt";
export var QrcodeDesc = "index-module--QrcodeDesc--eKO+O";
export var QrcodeWrap = "index-module--QrcodeWrap--dInAa";