import React, { useState, useEffect, useRef } from "react"
import classnames from 'classnames'
import Seo from "components/seo"
import Layout from "layouts/zh"
import Button, { ButtonGroup } from "components/Base/Button"
import Dialog from 'components/Base/Dialog'
import * as styles from './index.module.less'

const BookMall: React.FC = props => {
  return (
    <Layout>
      <Seo 
        title="实体书籍 - 神策数据"
        description=""
        keywords=""
        saTitle="首页-实体书籍" />
      <main className={styles.BookMall}>
        <BookMallState />
      </main>
    </Layout>
  );
}

const BookMallState: React.FC = props => {
  const [current, setCurrent] = useState<BookProps>();
  const [visible, setVisible] = useState<boolean>(false);

  const bookList: BookProps[] = [
    {
      thumb: require('assets/images/bookmall/book-01.png').default,
      bookName: '数据驱动：从方法到实践',
      keywords: '数据驱动，数据分析，智能应用，行业案例',
      pagesNumber: 216,
      publishHouse: '机械工业出版社',
      publishTime: '2018-03-01',
      price: 49,
    },
    {
      thumb: require('assets/images/bookmall/book-02.png').default,
      bookName: 'Android 全埋点解决方案【Android AutoTrack Solution】',
      keywords: '数据埋点，数据采集，Android，解决方案',
      pagesNumber: 328,
      publishHouse: '机械工业出版社',
      publishTime: '2019-04-01',
      price: 89,
    },
    {
      thumb: require('assets/images/bookmall/book-03.png').default,
      bookName: 'iOS 全埋点解决方案【iOS AutoTrack Solution】',
      keywords: '数据埋点，数据采集，iOS，解决方案',
      pagesNumber: 301,
      publishHouse: '机械工业出版社',
      publishTime: '2020-06-01',
      price: 89,
    },
  ];

  const handleClick = (item: BookProps) => {
    setCurrent(item);
    setVisible(true);
  }

  return (
    <>
      <div className={styles.Banner}>
        <h1>线上书城</h1>
      </div>
      <div className={styles.BookList}>
        {bookList?.map((item, index) => <BookItem key={item.bookName} 
          thumb={item.thumb}
          bookName={item.bookName}
          keywords={item.keywords}
          pagesNumber={item.pagesNumber}
          publishHouse={item.publishHouse}
          publishTime={item.publishTime}
          onClick={() => handleClick(item)}
        />)}
      </div>
      <Dialog className={styles.DialogBook} visible={visible} width={760} hideFooter={true} onClose={() => setVisible(false)}>
        <div className={styles.DialogBookWrap}>
          <div className={styles.DialogBookInfo}>
            <h2>{current?.bookName}</h2>
            <p className={styles.Price}>售价：{current?.price} 元</p>
            <p className={styles.QrcodeDesc}>您可微信扫描右侧二维码，添加客服小姐姐购买哟～</p>
          </div>
          <div className={styles.QrcodeWrap}>
            <img src={require('assets/images/bookmall/qrcode.png').default} alt="" />
          </div>
        </div>
      </Dialog>
    </>
  );
}

interface BookProps {
  thumb?: string;
  bookName?: string;
  keywords?: string;
  pagesNumber?: number;
  publishHouse?: string;
  publishTime?: string;
  price?: number;
}
interface BookItemProps extends BookProps {
  className?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}
const BookItem: React.FC<BookItemProps> = props => {
  const {
    thumb,
    bookName,
    keywords,
    pagesNumber,
    publishHouse,
    publishTime,
    onClick
  } = props;
  return (
    <div className={classnames(styles.BookItem)}>
      <div className={styles.BookThumb}>
        <img src={thumb} alt="" />
        <h2>{bookName}</h2>
      </div>
      <div className={styles.BookContent}>
        <h2>{bookName}</h2>
        <div className={styles.BookDesc}>
          <p>关键词：{keywords}</p>
          <p>页数：{pagesNumber} 页</p>
          <p>出版社：{publishHouse}</p>
          <p>出版时间：{publishTime}</p>
        </div>
        <ButtonGroup className={styles.BookButtonGroup}>
          <Button btnType="primary" onClick={onClick}>点击购买</Button>
        </ButtonGroup>
      </div>
    </div>
  );
}

export default BookMall;